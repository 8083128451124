<script setup lang="ts">
const menuStore = useMenuStore()
const { collapseMenus } = menuStore
const { isMenuCollapsed, isChangeAreaMenuCollapsed } = storeToRefs(menuStore)
const { isAdmin } = storeToRefs(useAuthStore())
</script>

<template>
  <div
    class="grid grid-rows-[3rem_calc(100vh-3rem)] md:grid-rows-[3rem_calc(100vh-7rem)_4rem]">
    <Header />
    <div
      class="flex overflow-hidden bg-secondary-gray-300 2xl:bg-secondary-gray-100"
      :class="{ 'bg-white 2xl:bg-white': isAdmin }">
      <AdminMenu v-if="isAdmin" />
      <SiteAssessmentMenu v-else />
      <div class="mx-auto w-full overflow-y-auto 2xl:max-w-[1700px]">
        <slot />
      </div>
      <ChangeAreaMenu />
    </div>
    <div
      class="z-30 hidden bg-secondary-gray-100 px-spacing-2xl py-spacing-sm md:flex">
      <SvgoSecuritasTextLogoBlack
        v-if="!$isProtectasUser.value"
        class="size-14" />
      <SvgoProtectasTextLogoBlack v-else class="size-14" />
    </div>
  </div>
  <DimOverlay
    v-if="!isMenuCollapsed || !isChangeAreaMenuCollapsed"
    :class="{
      'z-[91] lg:z-[91]': !isMenuCollapsed || !isChangeAreaMenuCollapsed,
    }"
    @click="collapseMenus" />

  <InstallAppPrompt />
  <Notification />
</template>
