<script setup lang="ts">
const { toggleMenu } = useMenuStore()
const { isMenuCollapsed } = storeToRefs(useMenuStore())
</script>

<template>
  <div
    class="font-secu-body-bold z-[100] cursor-auto justify-between bg-primary-navyblue-850">
    <div class="flex h-full gap-spacing-sm">
      <button class="pl-spacing-sm sm:hidden" @click="toggleMenu">
        <SvgoHamburger class="size-6" fill="white" />
      </button>
      <div
        class="flex w-20 cursor-default items-center gap-spacing-sm bg-primary-navyblue-900 p-spacing-sm text-white transition-transform">
        <div class="flex gap-spacing-sm">
          <SvgoAdvisor />
        </div>
        <button
          class="hidden items-center rounded-md bg-primary-navyblue-700 p-spacing-xs sm:flex"
          @click="toggleMenu">
          <SvgoArrowRight v-if="isMenuCollapsed" class="size-6 fill-white" />
          <SvgoArrowLeft v-else class="size-6 fill-white" />
        </button>
      </div>
      <div
        class="fixed left-0 top-0 z-50 hidden w-64 cursor-default items-center justify-between gap-spacing-sm bg-primary-navyblue-900 p-spacing-sm text-white transition-transform duration-300 sm:flex"
        :class="{
          'translate-x-0': !isMenuCollapsed,
          '-translate-x-full': isMenuCollapsed,
        }">
        <div class="flex gap-spacing-sm">
          <SvgoAdvisor /><span>Advisor</span>
        </div>
        <button
          class="hidden items-center rounded-md bg-primary-navyblue-700 p-spacing-xs sm:flex"
          @click="toggleMenu">
          <SvgoArrowLeft class="size-6 fill-white" />
        </button>
      </div>
    </div>
  </div>
</template>
