<script setup lang="ts">
const menuStore = useMenuStore()
const { isChangeAreaMenuCollapsed, addAreaPopup } = storeToRefs(menuStore)
const projectStore = useProjectStore()
const { selectedProject, selectedArea } = storeToRefs(projectStore)
const localePath = useLocalePath()
function getAreaLink(areaId: string) {
  return localePath(`/projects/${selectedProject.value?.id}/${areaId}`)
}
function setSelectedArea(area: Area) {
  isChangeAreaMenuCollapsed.value = true
  selectedArea.value = area
}
function addAreas() {
  isChangeAreaMenuCollapsed.value = true
  addAreaPopup.value = true
}
</script>

<template>
  <div
    v-if="!isChangeAreaMenuCollapsed"
    class="fixed right-0 top-12 z-[100] flex h-[calc(100vh-3rem)] w-72 flex-col bg-white px-spacing-sm py-spacing-lg transition-transform duration-300"
    :class="{
      'translate-x-0': !isChangeAreaMenuCollapsed,
      '-translate-x-full': isChangeAreaMenuCollapsed,
    }">
    <div class="mb-spacing-md flex">
      <div class="secu-headline-bold shrink grow basis-0">
        {{ $t('areas') }}
      </div>
      <SvgoCross
        class="relative size-6 cursor-pointer fill-black"
        @click="isChangeAreaMenuCollapsed = true" />
    </div>
    <div class="flex-grow overflow-y-auto">
      <ul class="space-y-spacing-sm2">
        <NuxtLink
          v-for="(item, index) in selectedProject?.areas"
          :key="index"
          :to="getAreaLink(item.id as string)"
          class="block rounded-lg px-spacing-sm2 py-spacing-md2 hover:bg-secondary-gray-200"
          :class="{
            'bg-secondary-gray-200': selectedArea?.id === item?.id,
          }"
          @click="setSelectedArea(item)">
          <li>
            <p class="secu-body-small-bold">
              {{ $tx(item.name ? item.name : item.areaType?.name) }}
            </p>
          </li>
        </NuxtLink>
      </ul>
    </div>
    <SButton
      class="secu-body-small-bold h-fit w-full bg-accent-purple-400 text-white"
      :text="$t('add_more_areas')"
      @click="addAreas" />
  </div>
</template>
