<script setup lang="ts">
import { watchEffect } from 'vue'

const { showNotification, error, customError } = storeToRefs(useProjectStore())
const intervalRef = ref()

function closeNotification() {
  error.value = null
  customError.value = null
  showNotification.value = false
}

watchEffect(() => {
  if (showNotification.value === true) {
    intervalRef.value = setInterval(() => {
      closeNotification()
    }, 5000)
  }
  else if (intervalRef.value) {
    clearInterval(intervalRef.value)
    intervalRef.value = null
  }
})
</script>

<template>
  <transition
    enter-from-class="opacity-0"
    leave-to-class="opacity-0"
    enter-active-class="transition duration-500"
    leave-active-class="transition duration-1000">
    <div
      v-if="showNotification"
      class="absolute bottom-28 right-1/2 z-[100] flex w-1/4 min-w-60 translate-x-1/2 items-center justify-start gap-4 self-stretch rounded bg-primary-navyblue-800 p-4 text-white shadow">
      <div
        class="secu-body-bold flex shrink grow basis-0 items-center space-x-spacing-sm">
        <SvgoError v-if="error || customError" />
        <SvgoSuccess v-else />
        <p>
          {{
            customError ? customError : error ? 'Something went wrong, contact an administrator' : 'Successfully saved'
          }}
        </p>
      </div>
      <div />
      <button class="m-0 h-fit" @click="closeNotification()">
        <SvgoClose class="relative size-5" />
      </button>
    </div>
  </transition>
</template>
