<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints(breakpointsTailwind)
const desktop = breakpoints.greaterOrEqual('xl')
const menu = useMenuStore()
const { items } = storeToRefs(menu)
const localePath = useLocalePath()
</script>

<template>
  <div
    class="z-[90] hidden w-20 bg-white lg:flex"
    @mouseenter="desktop ? (menu.isMenuCollapsed = false) : null">
    <ul
      class="flex flex-col gap-spacing-xl border-r border-secondary-gray-200 bg-white text-primary-navyblue-900">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="secu-body-bold flex flex-col px-spacing-sm first:mt-5"
        :class="{
          'border-l-2 border-accent-purple-400 text-accent-purple-400':
            menu.menuItemSelected === item.translate_key,
        }"
        @click="menu.selectMenuItem(index as number)">
        <NuxtLink
          :to="localePath(item.link)"
          class="flex cursor-pointer gap-spacing-sm truncate">
          <component
            :is="item.icon"
            class="size-6"
            :class="{
              'fill-black': item.translate_key !== menu.menuItemSelected,
              '!fill-accent-purple-400':
                menu.menuItemSelected === item.translate_key,
            }" />
          <SvgoArrowDown
            v-if="item.subItems"
            class="ml-spacing-sm size-6 fill-black"
            :class="{
              'fill-black': item.translate_key !== menu.menuItemSelected,
              '!fill-accent-purple-400':
                menu.menuItemSelected === item.translate_key,
            }" />
        </NuxtLink>
      </li>
    </ul>
  </div>
  <!-- This is an expanded menu with the transition effect that opens up on top of the collapsed menu -->
  <div
    class="fixed left-0 top-12 z-[100] w-64 bg-white transition-transform duration-300"
    :class="{
      'translate-x-0': !menu.isMenuCollapsed,
      '-translate-x-full': menu.isMenuCollapsed,
    }"
    @mouseleave="desktop ? (menu.isMenuCollapsed = true) : null">
    <ul
      class="flex h-screen w-full flex-col gap-spacing-xl border-r-[1px] bg-white text-primary-navyblue-900">
      <li
        v-for="(item, index) in items as MenuItem[]"
        :key="index"
        class="secu-body-bold flex flex-col px-spacing-sm first:mt-5"
        @click="menu.selectMenuItem(index as number)">
        <div
          class="flex items-center justify-between gap-spacing-sm"
          :class="{
            'border-l-2 border-accent-purple-400 text-accent-purple-400':
              menu.menuItemSelected === item.translate_key,
          }">
          <NuxtLink
            :to="item.subItems ? item.link + item.subItems[0].link : item.link"
            class="flex w-full cursor-pointer gap-spacing-sm truncate">
            <component
              :is="item.icon"
              class="size-6"
              :class="{
                'fill-black': item.translate_key !== menu.menuItemSelected,
                '!fill-accent-purple-400':
                  menu.menuItemSelected === item.translate_key,
              }" />
            <p class="grow truncate">
              {{ $t(item.translate_key) }}
            </p>
            <SvgoArrowDown
              v-if="item.subItems"
              class="size-6 fill-black"
              :class="{
                'fill-black': item.translate_key !== menu.menuItemSelected,
                '!fill-accent-purple-400':
                  menu.menuItemSelected === item.translate_key,
              }" />
          </NuxtLink>
        </div>
        <ul v-if="item.subItems" class="pt-spacing-md">
          <li
            v-for="(subItem, subIndex) in item.subItems as SubItem[]"
            :key="subIndex"
            :class="{
              'rounded bg-secondary-gray-200 text-accent-purple-400':
                menu.subMenuItemSelected === subItem.translate_key,
            }"
            @click.stop="menu.selectMenuItem(index as number, subIndex)">
            <NuxtLink
              class="flex items-center py-spacing-sm pl-10"
              :to="item.link + subItem.link">
              <p class="grow">
                {{ $t(subItem.translate_key) }}
              </p>
              <SvgoArrowRight
                class="ml-spacing-sm size-4 flex-shrink-0 fill-black">
                />
              </SvgoArrowRight>
            </NuxtLink>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
