<template>
  <div
    v-if="$pwa?.offlineReady || $pwa?.needRefresh"
    class="fixed bottom-14 right-0 z-[200] m-4 rounded-md border border-gray-400 bg-white p-3 text-left shadow-lg">
    <div class="mb-2">
      <span v-if="$pwa.offlineReady">App ready to work offline</span>
      <span v-else>
        New content available, click on reload button to update.
      </span>
    </div>
    <button
      v-if="$pwa.needRefresh"
      class="mr-1 rounded border border-gray-400 px-2 py-1"
      @click="$pwa?.updateServiceWorker()">
      Reload
    </button>
    <button
      class="rounded border border-gray-400 px-2 py-1"
      @click="$pwa?.cancelPrompt()">
      Close
    </button>
  </div>
  <div
    v-if="$pwa?.showInstallPrompt && !$pwa?.offlineReady && !$pwa?.needRefresh"
    role="alert"
    class="fixed bottom-14 right-0 z-[200] m-4 rounded-md border border-gray-400 bg-white p-3 text-left shadow-lg">
    <div class="message mb-2">
      <span>Install PWA</span>
    </div>
    <button
      class="mr-1 rounded border border-gray-400 px-2 py-1"
      @click="$pwa?.install()">
      Install
    </button>
    <button
      class="rounded border border-gray-400 px-2 py-1"
      @click="$pwa?.cancelInstall()">
      Cancel
    </button>
  </div>
</template>
