<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints(breakpointsTailwind)
const desktop = breakpoints.greaterOrEqual('xl')
const menu = useMenuStore()
const localePath = useLocalePath()
</script>

<template>
  <div
    class="z-[90] hidden w-20 border-r-[1px] bg-white lg:flex"
    @mouseenter="desktop ? (menu.isMenuCollapsed = false) : null">
    <ul class="flex flex-col gap-spacing-xl bg-white text-primary-navyblue-900">
      <li
        v-for="(item, index) in menu.items"
        :key="index"
        class="secu-body-bold flex flex-col px-spacing-sm first:mt-5"
        :class="{
          'border-l-2 border-accent-purple-400 text-accent-purple-400':
            menu.menuItemSelected === item.translate_key,
        }">
        <!-- <div
          class="flex menu.items-center justify-between gap-spacing-sm py-spacing-xs"
        > -->
        <NuxtLink
          :to="localePath(item.link)"
          class="flex cursor-pointer gap-spacing-sm truncate"
          @click="menu.selectMenuItem(index)">
          <component
            :is="item.icon"
            class="size-6"
            :class="{
              'stroke-black stroke-2': item.translate_key === 'projects',
              'fill-black': item.translate_key !== 'projects',
              '!fill-accent-purple-400':
                menu.menuItemSelected === item.translate_key && item.translate_key !== 'projects',
              'stoke-2 !stroke-accent-purple-400':
                item.translate_key === 'projects' && menu.menuItemSelected === 'projects',
            }" />
        </NuxtLink>
      </li>
    </ul>
  </div>
  <!-- This is an expanded menu with the transition effect that opens up on top of the collapsed menu -->
  <div
    class="fixed left-0 top-12 z-[100] w-64 bg-white transition-transform duration-300"
    :class="{
      'translate-x-0': !menu.isMenuCollapsed,
      '-translate-x-full': menu.isMenuCollapsed,
    }"
    @mouseleave="desktop ? (menu.isMenuCollapsed = true) : null">
    <ul
      class="flex h-screen w-full flex-col gap-spacing-xl border-r-[1px] bg-white text-primary-navyblue-900">
      <li
        v-for="(item, index) in menu.items"
        :key="index"
        class="secu-body-bold flex flex-col px-spacing-sm first:mt-5"
        :class="{
          'border-l-2 border-accent-purple-400 text-accent-purple-400':
            menu.menuItemSelected === item.translate_key,
        }"
        @click="menu.selectMenuItem(index)">
        <div class="flex items-center justify-between gap-spacing-sm">
          <NuxtLink
            :to="localePath(item.link)"
            class="flex w-full cursor-pointer gap-spacing-sm truncate">
            <component
              :is="item.icon"
              class="size-6"
              :class="{
                'stroke-black stroke-2': item.translate_key === 'projects',
                'fill-black': item.translate_key !== 'projects',
                '!fill-accent-purple-400':
                  menu.menuItemSelected === item.translate_key && item.translate_key !== 'projects',
                'stoke-2 !stroke-accent-purple-400':
                  item.translate_key === 'projects' && menu.menuItemSelected === 'projects',
              }" />
            <p :to="item.link ? item.link : null" class="truncate">
              {{ $t(item.translate_key) }}
            </p>
          </NuxtLink>
        </div>
      </li>
    </ul>
  </div>
</template>
